import { GetServerSideProps } from "next";
import useCookie from "react-use-cookie";
import { useQuery } from "react-query";
import yaml from "js-yaml";
// import { Activity } from "@geist-ui/react-icons";
import { useCurrentUser, CurrentUser } from "../lib/user";
import auth0 from "../lib/auth0";
import Page from "../components/Page";
import Button from "../components/Button";
import Bounce from "../components/Bounce";

interface ReleaseRequest {
  files: Array<{
    url: string;
  }>;
}

function channelForLocation(location): "alpha" | "beta" | "latest" {
  const channel = location.hostname.split(".")[0];

  switch (channel) {
    case "alpha":
    case "beta":
      return channel;
    default:
      return "beta"; // override to "latest" until production
  }
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const session = auth0.getSession(context.req, context.res);

  return {
    props: { currentUser: session?.user || null },
  };
};

export default function Index(props: {
  currentUser: CurrentUser;
}): JSX.Element {
  const [downloaded, setDownloaded] = useCookie("downloaded");
  const currentUser = useCurrentUser({ initialValue: props.currentUser }); // TODO: Look into hydrating currentUser vs initialData
  const release = useQuery("release", async () => {
    const channel = channelForLocation(globalThis.location);
    const isWindows = /win/i.test(globalThis.navigator?.platform);
    const request = isWindows
      ? await fetch(
          `https://download.dabounce.app/file/dabounce-releases/${channel}.yml`
        )
      : await fetch(
          `https://download.dabounce.app/file/dabounce-releases/${channel}-mac.yml`
        );

    const release: ReleaseRequest = yaml.load(
      await request.text()
    ) as ReleaseRequest;

    const download = release.files.find(
      (file) => file.url.endsWith(".exe") || file.url.endsWith(".dmg")
    );

    return {
      channel,
      isWindows,
      download: {
        ...download,
        absoluteUrl: `https://download.dabounce.app/file/dabounce-releases/${download.url}`,
      },
    };
  });

  return (
    <Page
      currentUser={{ initialValue: props.currentUser }}
      onClick={() => {
        if (downloaded === "1") {
          setDownloaded("0");
        }
      }}
    >
      <style jsx>{`
        .modal {
          flex: 1;
          display: flex;
          flex-direction: column;
          width: 100%;
          min-width: 0;
          max-width: 500px;
          margin: 0 auto;
          overflow: auto;
        }

        .main {
          margin: 48px auto;
        }

        .beta-form {
          flex: 1;
        }

        .intro-video {
          position: fixed;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          // border: 1px solid #0070f3;
          width: 80vw;
          height: 80vh;
        }
      `}</style>
      <div className="modal">
        {currentUser.data?.currentUser ? (
          <div className="main">
            {/* <h1>Activity</h1>
            <Activity size={128} />
            <p>
              Here you'll find activity for all your account and bounces. Get started by downloading the
              Dabounce companion app to bounce your next session directly to the cloud. 
            </p> */}
            {downloaded !== "1" ? (
              <Bounce>
                <Button
                  type="success"
                  onClick={() => {
                    setDownloaded("1");
                    window.location.href = `${release.data.download.absoluteUrl}?source=index`;
                  }}
                >
                  {release.status === "success"
                    ? release.data.isWindows
                      ? "Download for Windows"
                      : "Download for macOS"
                    : "Download for…"}
                </Button>
              </Bounce>
            ) : (
              <iframe
                className="intro-video"
                src="https://www.youtube.com/embed/NwpPNCwI5Ic?rel=0&autoplay=1&modestbranding=1&cc_load_policy=1&vq=hd1080"
                title="First project with Dabounce"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </div>
        ) : (
          <iframe
            className="beta-form"
            frameBorder={0}
            src="https://dabounce-beta.paperform.co?embed=1&takeover=0&inline=1&popup=0&_in=0"
            data-hj-allow-iframe="true"
          />
        )}
      </div>
    </Page>
  );
}
