export default function Bounce(props: {
  children: React.ReactNode;
  duration?: number;
  delay?: number;
  scale?: number;
}): JSX.Element {
  return (
    <div className="bounce">
      <style jsx>
        {`
          @keyframes bounce {
            from,
            to {
              transform: scale(1);
            }
            50% {
              transform: scale(${props.scale || 1.3});
            }
          }

          .bounce {
            animation-name: bounce;
            animation-duration: ${props.duration || 250}ms;
            animation-delay: ${props.delay || 0}ms;
          }
        `}
      </style>
      {props.children}
    </div>
  );
}
